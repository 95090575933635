import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import logo from '../../assets/images/nls-logo.png';
import {Button, Input} from '../../components';
import {useMutation} from 'react-query';
import toast from 'react-hot-toast';

import {forgotPasswordApi} from '@apis/';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const history = useHistory();

  const forgotPassword = useMutation(params => forgotPasswordApi(params));

  useEffect(() => {
    if (forgotPassword?.isError) {
      if (typeof forgotPassword?.error === 'string') {
        toast.error(forgotPassword?.error);
      } else {
        toast.error('Faild to send token');
      }
      forgotPassword?.reset();
    }

    if (forgotPassword?.isSuccess) {
      toast.success('A reset link has been sent to your email address');
    }
  }, [email, forgotPassword, history]);

  const handleReset = async e => {
    e.preventDefault();
    await forgotPassword.mutateAsync({
      email,
    });
  };

  return (
    <div className="xl:mx-96 lg:mx-60 flex justify-center items-center mx-auto">
      <div className="flex flex-auto flex-col items-center justify-center next-gradient h-screen p-16 relative">
        <div className="w-full items-center my-4">
          <h1 className="font-bold text-3xl text-white text-center">
            Forgot my password
          </h1>
          <p className="font-light text-xs text-white text-center py-3 px-20">
            Enter the email you used to register and we will send you a password
            reset link.
          </p>
        </div>
        <div className="w-full items-center">
          <Input
            dark
            value={email}
            onChange={e => setEmail(e.target.value)}
            label="Email"
          />
        </div>
        <div
          className="absolute inset-x-0 bottom-24"
          style={{ margin: "0 60px" }}
        >
          <Button block label="Reset My Password" onClick={handleReset} />
          <div className="flex flex-row justify-center items center my-5">
            <p className="text-white text-xs">Don't have an account?</p>
            <Link to="/register" className="text-white text-xs px-2 underline">
              Sign up now
            </Link>
          </div>
          <div className="flex flex-row justify-center items center my-5">
            <p className="text-white text-xs">Already have an account?</p>
            <Link to="/login" className="text-white text-xs px-2 underline">
              Sign in now
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ForgotPassword;
