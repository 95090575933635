import React, { useState, useEffect, useRef } from "react"
import { useMutation } from "react-query"

import { ContentWrapper, Input, Card, Button } from "@components"

import { getUser, updateUser } from "@apis"

const FileUploadButton = ({ innerRef, ...rest }) => (
  <div className="flex p-2">
    <input ref={innerRef} type="file" id="upload" hidden />
    <label
      htmlFor="upload"
      className="text-red text-xs uppercase cursor-pointer"
    >
      Upload Image
    </label>
  </div>
)

function UserDetail(props) {
  const upload = useRef(null)
  const id = props.params
  const [profile, setProfile] = useState({})
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [patient, setPatient] = useState("")
  const [dob, setDob] = useState("")
  const [phone, setPhone] = useState("")

  const updateU = useMutation(params => {
    updateUser(params)
    getUserDetails()
  })

  const update = async e => {
    e.preventDefault()

    let user = {
      id: id,
      name: name,
      email: email,
      patient_name: patient,
      dob: dob,
      phone_number: phone
    }

    if (upload.current.files[0]) {
      user.profile_picture = upload.current.files[0]
    }

    // console.log("filee ", user)

    await updateU.mutateAsync(user)

    getUserDetails()
  }

  function getUserDetails() {
    getUser(id)
      .then(resp => {
        // console.log(resp)
        setProfile(resp)
        setName(resp.name)
        setEmail(resp.email)
        setPatient(resp.patient_name)
        setDob(resp.dob)
        setPhone(resp.phone_number)
      })
      .catch(e => console.error(e))
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  return (
    <ContentWrapper breadcrumb="Dashboard / Users">
      <div className="flex flex-col w-full">
        <form>
          <h2>User Details</h2>
          <Input
            value={email}
            label="Email"
            onChange={e => setEmail(e.target.value)}
          />
          <Input
            value={name}
            label="name"
            onChange={e => setName(e.target.value)}
          />
          <Input
            value={patient}
            label="patient name"
            onChange={e => setPatient(e.target.value)}
          />
          <Input
            value={phone}
            label="phone number"
            onChange={e => setPhone(e.target.value)}
          />
          <Input
            value={dob}
            label="date of birth"
            onChange={e => setDob(e.target.value)}
          />
          <label>Profile Picture </label>
          {profile.profile_picture ? (
            <img src={profile.profile_picture} width="200" height="200" />
          ) : (
            <p></p>
          )}

          <Card
            title="Video file"
            rightContent={<FileUploadButton innerRef={upload} />}
          >
            {upload.current?.files[0] && (
              <div className="flex justify-center items-center">
                <p className="text-xs text-gray-400 ">
                  {upload.current.files[0].name}
                </p>
              </div>
            )}
          </Card>

          <Button
            label="Save"
            type="submit"
            onClick={update}
            style={{ marginTop: 20, width: 200 }}
          />
        </form>
      </div>
    </ContentWrapper>
  )
}

export default UserDetail
