import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import { Button } from "@components"

import { ReactComponent as Search } from "@images/search.svg"

const ContentWrapper = ({
  children,
  breadcrumb,
  hasSearch = false,
  onButtonOneClick,
  onButtonTwoClick
}) => {
  const history = useHistory()
  return (
    <>
      <div className="hidden lg:flex w-full pr-6">
        <div className="w-3/5 hidden lg:flex px-8">
          <div className="flex flex-1 flex-col justify-center">
            <p className="text-xs text-menu-title">{breadcrumb}</p>
          </div>
        </div>
        <div className="w-2/5 h-full hidden lg:flex items-center justify-end pl-5"></div>
      </div>
      <div className="container py-10 h-64 px-8">
        <div className="w-full h-full rounded">{children}</div>
      </div>
    </>
  )
}

export default ContentWrapper;
