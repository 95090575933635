import React from 'react';
import Spinner from '../spinner';

const Button = props => {
  let background;
  let textColor;
  let border;
  switch (props.type) {
    case 'primary':
      background = 'bg-lightGreen hover:bg-greenHover';
      textColor = 'text-green';
      break;
    case 'secondary':
      background = 'bg-btnSecondaryBg';
      textColor = 'text-btnSecondaryText';
      break;
    case 'danger':
      background = 'bg-red';
      textColor = 'text-white';
      break;
    case 'link':
      background = 'bg-transparent';
      textColor = 'text-red';
      break;
    default:
      background = "bg-btnSecondaryBg"
      textColor = "text-blue"
      border = 'border border-white border-opacity-10';
      break;
  }
  return (
    <div className={`${props.block ? 'w-full' : 'w-max'}`}>
      <button
        {...props}
        className={`${background} min-w-full py-2 px-4 rounded-md ${textColor} ${border} justify-center items-center flex ${props.type !==
          'link' && 'shadow-sm'} ${
          props.disabled
            ? 'cursor-not-allowed bg-opacity-50'
            : 'cursor-pointer bg-opacity-100'
        }`}>
        <div className="flex flex-row items-center">
          {props.loading && <Spinner />}
          <p className={`${textColor} text-center uppercase text-xs`}>
            {props.label}
          </p>
        </div>
      </button>
    </div>
  );
};

export default Button;
