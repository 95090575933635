import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import toast from 'react-hot-toast';
import {useMutation} from 'react-query';

import {Input, Checkbox, Button} from '@components';
import {registerApi} from '@apis';
import useAuth from '@context/useAuth';

import logo from '@images/nls-logo.png';

const Register = () => {
  const {onRegister} = useAuth();
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeTos: false,
  });

  const history = useHistory();

  const register = useMutation(params => registerApi(params));

  useEffect(() => {
    if (register?.isError) {
      if (typeof register?.error === 'string') {
        toast.error(register?.error);
      } else {
        toast.error('register Error');
      }
      register?.reset();
    }

    if (register?.isSuccess) {
      toast.success("Registration Successful. Now, you will be redirected to login page.")
      //onRegister({token: register?.data?.api_token});
      history.replace('/login');
    }
  }, [history, onRegister, register]);

  const handleRegister = async e => {
    e.preventDefault();
    const {email, password, firstName, lastName, confirmPassword} = state;
    if (password !== confirmPassword) {
      await toast.error('Password does not match');
    }
    await register.mutate({
      email,
      password,
      first_name: firstName,
      last_name: lastName,
    });
  };

  return (
    <div className="xl:mx-96 lg:mx-60 flex justify-center items-center mx-auto">
      <div className="flex flex-col flex-auto items-center justify-center next-gradient p-16 relative">
        <div className="w-full items-center my-4 px-20">
          <h1 className="font-bold text-3xl text-white text-center">Welcome</h1>
          <p className="font-light text-xs text-white text-center py-3">
            Create a new account
          </p>
        </div>
        <div className="w-full items-center">
          <Input
            dark
            value={state.firstName}
            onChange={e => setState({ ...state, firstName: e.target.value })}
            label="First Name"
            placeholder="Enter First Name"
          />
          <Input
            dark
            value={state.lastName}
            onChange={e => setState({ ...state, lastName: e.target.value })}
            label="Last Name"
            placeholder="Enter Last Name"
          />
          <Input
            dark
            value={state.email}
            onChange={e => setState({ ...state, email: e.target.value })}
            label="Email"
            placeholder="Enter Email"
          />
          <Input
            dark
            type="password"
            value={state.password}
            onChange={e => setState({ ...state, password: e.target.value })}
            label="Password"
            placeholder="Enter Password"
          />
          <Input
            dark
            type="password"
            value={state.confirmPassword}
            onChange={e =>
              setState({ ...state, confirmPassword: e.target.value })
            }
            label="Confirm Password"
            placeholder="Confirm Password"
          />
          <Checkbox
            checked={state.agreeTos}
            onChange={e => setState({ ...state, agreeTos: e.target.checked })}
            label="By signing up you agree to the Hospice terms and conditions"
          />
        </div>
        <div
          className=" inset-x-0 "
          style={{ marginTop:20,width:'100%' }}
        >
          <Button
            block
            onClick={handleRegister}
            loading={register?.isLoading}
            disabled={
              register?.isLoading ||
              !state.firstName ||
              !state.lastName ||
              !state.password ||
              !state.confirmPassword ||
              !state.agreeTos
            }
            label="Sign Up"
          />
          <div className="flex flex-row justify-center items center my-5">
            <p className="text-white text-xs">Already have an account?</p>
            <Link to="/login" className="text-white text-xs px-2 underline">
              Sign in now
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Register;
