import React, { useState } from "react"
import { useTable, useFilters, useSortBy } from "react-table"
import { ReactComponent as Search } from "@images/search.svg"

export default function Table({ columns, data, filter }) {
  const [filterInput, setFilterInput] = useState("")
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter
  } = useTable(
    {
      columns,
      data
    },
    useFilters,
    useSortBy
  )

  const handleFilterChange = e => {
    const value = e.target.value || undefined
    setFilter(filter, value)
    setFilterInput(value)
  }

  // console.log(headerGroups)

  // Render the UI for your table
  return (
    <>
      <div className="table-responsive">
        <div className="relative w-full">
          <input
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Search"}
            className="border border-gray-100 focus:outline-none focus:border-indigo-700 rounded w-full text-sm text-gray-500 bg-gray-100 pl-12 py-2"
          />
          <div className="text-gray-500 absolute ml-4 top-0 bottom-0 right-6 m-auto w-4 h-4">
            <Search height={18} />
          </div>
        </div>

        <table
          {...getTableProps()}
          className="min-w-full"
          style={{ backgroundColor: "#2E84B3", color: "#ffffff" }}
        >
          <thead
            style={{ backgroundColor: "#024669" }}
            className="bg-tableHeaderBg p-4"
          >
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{
                  borderBottom: "2px solid rgba(0, 0, 0, 0.15)"
                }}
              >
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                    style={{ padding: 12, textAlign: "left" }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    borderBottom: "2px solid rgba(0, 0, 0, 0.15)"
                  }}
                >
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} style={{ padding: 12 }}>
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
