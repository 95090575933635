import React from 'react';

const Checkbox = props => {
  return (
    <div className="flex flex-row flex-grow py-3 items-center">
      <input
        {...props}
        type="checkbox"
        className="transparent rounded-sm my-2 p-2 border border-white"
      />
      <p className="text-white text-xs capitalize font-light px-3">
        {props.label}
      </p>
    </div>
  );
};

export default Checkbox;
