import React, { useState, useMemo, useEffect } from "react"
import { useMutation } from "react-query"
import Select from "react-select"

import { ContentWrapper, Table, Button, Input } from "@components"
import { getNurses, getCompany, generateHospice, generateNurse } from "@apis"

const Codes = () => {
  const [company, setCompany] = useState("")
  const [nurse, setNurse] = useState("")
  const [nurseCompany, setNurseCompany] = useState("")
  const [options, setOptions] = useState([])

  const updateC = useMutation(params => {
    generateHospice(params).then(() => {
      getCompany().then(req => {
        setDataCompany(req)
      })
    })
  })

  const updateCompany = async e => {
    e.preventDefault()

    await updateC.mutateAsync({
      name: company
    })
  }

  const updateN = useMutation(params => {
    generateNurse(params).then(() => {
      getNurses().then(req => {
        setDataNurse(req)
      })
    })
  })

  const updateNurse = async e => {
    e.preventDefault()

    await updateN.mutateAsync({
      name: nurse,
      company: nurseCompany
    })
  }

  const columns_company = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id"
      },
      {
        Header: "Hospice Company Name",
        accessor: "name"
      },
      {
        Header: "Patient’s Code",
        accessor: "code"
      }
    ],
    []
  )

  const columns_nurse = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id"
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Code",
        accessor: "code"
      },
      {
        Header: "Company",
        accessor: "company"
      }
    ],
    []
  )

  const [dataCompany, setDataCompany] = useState([])
  const [dataNurse, setDataNurse] = useState([])

  useEffect(() => {
    getCompany()
      .then(req => {
        setDataCompany(req)
      })
      .catch(e => console.error(e))

    getNurses()
      .then(req => {
        setDataNurse(req)
      })
      .catch(e => console.error(e))
  }, [])

  useEffect(() => {
    var arr = []
    dataCompany.forEach(el => {
      arr.push({ value: el.id, label: el.name })
    })
    setOptions(arr)
  }, [dataCompany])

  const colourStyles = {
    container: styles => ({
      ...styles,
      marginBottom: -20,
      padding: 0,
      top: 15
    })
  }

  return (
    <ContentWrapper breadcrumb="Dashboard / Video" hasSearch={false}>
      <div className="flex flex-col w-full">
        <form>
          <div className="grid gap-4 grid-cols-2 md:grid-cols-2">
            <div className="flex flex-col w-full">
              <Input
                value={company}
                onChange={e => setCompany(e.target.value)}
                placeholder={"Company Name"}
              />
            </div>
            <div className="flex flex-col w-full">
              <button
                className={`${true ? "bg-darkBlue" : "bg-transparent"} mx-1`}
                style={{ marginTop: 22, height: 30 }}
                onClick={updateCompany}
              >
                <p
                  className={`uppercase text-xs py-2 px-4 font-bold ${
                    true ? "text-white" : "text-btnSecondaryText"
                  }`}
                >
                  Generate Hospice Code
                </p>
              </button>
            </div>
          </div>
        </form>
        <Table columns={columns_company} data={dataCompany} filter={"name"} />
        <button>
          <div className="bg-tableHeaderBg py-2">
            <p className="text-btnSecondaryText text-xs text-center">
              show next 10 results
            </p>
          </div>
        </button>
      </div>
    </ContentWrapper>
  )
}

export default Codes
