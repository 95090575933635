import React, { useState, useMemo, useEffect } from "react"
import { useTable, useRowSelect, useFilters, useSortBy } from "react-table"

import { ContentWrapper, Button } from "@components"

import { ReactComponent as Gift } from "@images/gift.svg"
import { ReactComponent as User } from "@images/user.svg"
import { ReactComponent as Basket } from "@images/basket.svg"
import { ReactComponent as Star } from "@images/star.svg"
import debby from "@images/debby.png"
import userImg from "@images/user.svg"

import { getEmails, updateMailReq } from "@apis"

import { Table } from "@components"
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai"

function Emails() {
  const [data, setData] = useState([])

  const updateMail = (id, status) => {
    updateMailReq(id, {
      status: status
    })
      .then(() => {
        getEmails().then(result => {
          var newArray = result.filter(function (el) {
            return el.status == null
          })
          setData(newArray)
        })
      })
      .catch(() => {})
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: ""
      },
      {
        Header: "User Name",
        accessor: "user"
      },
      {
        Header: "Old Email",
        accessor: "old_email"
      },
      {
        Header: "New Email",
        accessor: "new_email"
      },
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: row => {
          return (
            <div className="grid gap-4 grid-cols-2 md:grid-cols-2">
              <div className="flex flex-col w-full">
                <button
                  className={`${true ? "bg-lightBlue" : "bg-transparent"} mx-1`}
                  onClick={() => {
                    updateMail(row.row.original.id, 0)
                  }}
                >
                  <p
                    className={`uppercase text-xs py-2 px-4 font-bold ${
                      true ? "text-white" : "text-btnSecondaryText"
                    }`}
                  >
                    Cancel
                  </p>
                </button>
              </div>
              <div className="flex flex-col w-full">
                <button
                  className={`${true ? "bg-darkBlue" : "bg-transparent"} mx-1`}
                  onClick={() => {
                    updateMail(row.row.original.id, 1)
                  }}
                >
                  <p
                    className={`uppercase text-xs py-2 px-4 font-bold ${
                      true ? "text-white" : "text-btnSecondaryText"
                    }`}
                  >
                    Approve
                  </p>
                </button>
              </div>
            </div>
          )
        }
      }
    ],
    []
  )

  useEffect(() => {
    ;(async () => {
      const result = await getEmails()
      var newArray = result.filter(function (el) {
        return el.status == null
      })
      setData(newArray)
    })()
  }, [])

  return (
    <ContentWrapper breadcrumb="Dashboard / Users">
      <div className="flex flex-col w-full">
        <Table columns={columns} data={data} filter={"user"} />
        <button>
          <div className="bg-tableHeaderBg py-2">
            <p className="text-btnSecondaryText text-xs text-center">
              show next 10 results
            </p>
          </div>
        </button>
      </div>
    </ContentWrapper>
  )
}

export default Emails
