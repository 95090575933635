import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';
import {Link, useLocation, useHistory} from 'react-router-dom';
import logo from '../../assets/images/nls-logo.png';
import {Button, Input} from '../../components';
import {useMutation} from 'react-query';

import {resetPasswordApi} from '@apis/';

const ResetPassword = () => {
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const location = useLocation();
  const history = useHistory();

  const resetPassword = useMutation(params => resetPasswordApi(params));

  useEffect(() => {
    const params = location?.search;
    setEmail(new URLSearchParams(params).get('email'));
    setOtp(new URLSearchParams(params).get('token'));
  }, [location?.search]);

  useEffect(() => {
    if (resetPassword?.isError) {
      if (typeof resetPassword?.error === 'string') {
        toast.error(resetPassword?.error);
      } else {
        toast.error('Faild to reset password');
      }
      resetPassword?.reset();
    }

    if (resetPassword?.isSuccess) {
      history.push('/login');
    }
  }, [email, resetPassword, history]);

  const handleReset = async e => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Password doesn't match");
    } else {
      await resetPassword.mutateAsync({
        email,
        token: otp,
        new_password: password,
      });
    }
  };

  return (
    <div className="xl:mx-96 lg:mx-60 flex justify-center items-center mx-auto">
      <div className="flex flex-col flex-auto items-center justify-center next-gradient h-screen p-16 relative">
        <div className="w-full items-center my-4 px-20">
          <h1 className="font-bold text-3xl text-white text-center">
            Forgot my password
          </h1>
          <p className="font-light text-xs text-white text-center py-3">
            Setup a new password
          </p>
        </div>
        <div className="w-full items-center">
          <Input
            dark
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="New Password"
            placeholder="Enter New Password"
          />
          <Input
            dark
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            label="Confirm Password"
            placeholder="Confirm Password"
          />
        </div>
        <div
          className="absolute inset-x-0 bottom-24"
          style={{ margin: "0 60px" }}
        >
          <Button
            block
            disabled={!password || !confirmPassword || resetPassword?.isLoading}
            loading={resetPassword?.isLoading}
            label="Reset My Password"
            onClick={handleReset}
          />
          <div className="flex flex-row justify-center items center my-5">
            <p className="text-white text-xs">Don't have an account?</p>
            <Link to="/register" className="text-white text-xs px-2 underline">
              Sign up now
            </Link>
          </div>
          <div className="flex flex-row justify-center items center my-5">
            <p className="text-white text-xs">Already have an account?</p>
            <Link to="/login" className="text-white text-xs px-2 underline">
              Sign in now
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ResetPassword;
