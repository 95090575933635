import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter} from 'react-router-dom';
import {Toaster} from 'react-hot-toast';
import Routes from './routes';

import { AuthProvider } from "@context/useAuth"

const queryClient = new QueryClient()

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Routes />
          <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              error: {
                style: {
                  background: "#5E0A0A",
                  color: "white"
                }
              }
            }}
          />
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App;
