import React, { useState, useMemo, useEffect } from "react"

import { ContentWrapper, Button } from "@components"

import { ReactComponent as Gift } from "@images/gift.svg"
import { ReactComponent as User } from "@images/user.svg"
import { ReactComponent as Basket } from "@images/basket.svg"
import { ReactComponent as Star } from "@images/star.svg"
import debby from "@images/debby.png"
import userImg from "@images/user.svg"
import profilePic from "../../assets/images/profileDefault.jpeg"
import { useHistory } from "react-router-dom"

import { getUsers, deleteUser } from "@apis"

import { Table } from "@components"

import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai"

function Dashboard({ navigation }) {
  // const handleDelete = id => {
  //   deleteUser(id)
  //   // console.log(">>>>>>>>>>>>" + id)
  //   // delete @TODO
  //   getUsers()
  //     .then(req => {
  //       setData(req)
  //     })
  //     .catch(e => console.error(e))
  // }

  const history = useHistory()

  const goToDetail = id => {
    history.replace("/users/" + id)
  }

  function removerUser(id) {
    deleteUser(id)
      .then(() => {
        getUsers()
          .then(req => {
            setData(req)
          })
          .catch(e => console.error(e))
      })
      .catch(e => console.error(e))
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id"
      },
      {
        Header: "Profile Picture",
        accessor: "profile_picture",
        Cell: row => {
          // console.log("props %o", row.row.original.profile_picture)
          return (
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <img
                  src={row.row.original.profile_picture || profilePic}
                  style={{ width: 50, height: 50 }}
                />
              </div>
            </div>
          )
        }
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Patient Name",
        accessor: "patient_name"
      },
      {
        Header: "Phone Number",
        accessor: "phone_number"
      },
      {
        Header: "Date of Birth",
        accessor: "dob"
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: row => {
          // console.log("props %o", row.row.original.id)
          return (
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <button
                  onClick={() => {
                    removerUser(row.row.original.id)
                  }}
                >
                  <AiFillDelete />
                </button>
                <i className="mdi mdi-delete-forever"></i>
              </div>
              <div className="flex flex-col w-full">
                <button
                  onClick={() => {
                    goToDetail(row.row.original.id)
                  }}
                >
                  <AiTwotoneEdit />
                </button>
              </div>
            </div>
          )
        }
      }
    ],
    []
  )

  const [data, setData] = useState([])

  useEffect(() => {
    getUsers()
      .then(req => {
        setData(req)
      })
      .catch(e => console.error(e))
  }, [])
  //get ... cache - network
  return (
    <ContentWrapper breadcrumb="Dashboard / Users">
      <div className="flex flex-col w-full">
        <Table columns={columns} data={data} filter={"name"} />
        <button>
          <div className="bg-tableHeaderBg py-2">
            <p className="text-btnSecondaryText text-xs text-center">
              show next 10 results
            </p>
          </div>
        </button>
      </div>
    </ContentWrapper>
  )
}

export default Dashboard
