import React from 'react';

const Input = ({label, dark, ...rest}) => {
  return (
    <div className="flex flex-col flex-grow py-3">
      <p
        className={`${
          dark ? "text-white" : "text-black"
        } text-xs uppercase font-light`}
      >
        {label}
      </p>
      <input
        {...rest}
        className={`${
          dark ? "bg-trueGray-100" : "bg-transparent"
        } rounded h-8 my-2 px-2 text-sm ${dark ? "border-0" : "border"}`}
      />
    </div>
  )
};

export default Input;
