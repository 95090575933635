import {ReactComponent as Home} from '@images/home.svg';
import {ReactComponent as Analytics} from '@images/analytics.svg';
import {ReactComponent as Gift} from '@images/gift.svg';
import {ReactComponent as Inventory} from '@images/inventory.svg';
import {ReactComponent as Marketing} from '@images/marketing.svg';
import {ReactComponent as Setup} from '@images/setup.svg';
import {ReactComponent as PointsSetup} from '@images/pointsSetup.svg';
import {ReactComponent as User} from '@images/user.svg';

export const storeMenus = [
  {
    title: "List of Users",
    breadcrumb: "Users /",
    path: "/",
    icon: ({ isActive }) => (
      <User
        className={`fill-current ${isActive ? "text-white" : "text-menu"}`}
      />
    )
  },
  {
    title: "Change E-mail Requests",
    breadcrumb: "Emails /",
    path: "/emails",
    icon: ({ isActive }) => (
      <Setup
        className={`fill-current ${isActive ? "text-white" : "text-menu"}`}
      />
    )
  },
  {
    title: "Add Education",
    breadcrumb: "Education /",
    path: "/education",
    icon: ({ isActive }) => (
      <Inventory
        className={`fill-current ${isActive ? "text-white" : "text-menu"}`}
      />
    )
  },
  {
    title: "Add HowTo",
    breadcrumb: "HowTo /",
    path: "/howto",
    icon: ({ isActive }) => (
      <Inventory
        className={`fill-current ${isActive ? "text-white" : "text-menu"}`}
      />
    )
  },
  {
    title: "Generate Hospice Code",
    breadcrumb: "Hospice Code /",
    path: "/codes",
    icon: ({ isActive }) => (
      <PointsSetup
        className={`fill-current ${isActive ? "text-white" : "text-menu"}`}
      />
    )
  }
]
