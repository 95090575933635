import {
  ForgotPassword,
  Login,
  Register,
  ResetPassword,
  Dashboard,
  UserDetail,
  Emails,
  Videos,
  Codes,
  Education,
  HowTo,
  EducationDetail,
  HowToDetail
} from "@screens/"
import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import useAuth from "@context/useAuth"
import { Layout } from "@components"

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuth()
  // console.log("====isAuthenticated===", isAuthenticated)
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route exact path="/reset-password">
        <ResetPassword />
      </Route>
      <Layout>
        <PrivateRoute exact path="/">
          <Dashboard />
        </PrivateRoute>
        <Route
          exact
          path="/users/:id"
          render={({ match, location }) => {
            return <UserDetail params={match.params.id} />
          }}
        />
        <PrivateRoute exact path="/emails">
          <Emails />
        </PrivateRoute>
        <PrivateRoute exact path="/education">
          <Education />
        </PrivateRoute>
        <Route
          exact
          path="/education/:id"
          render={({ match, location }) => {
            return <EducationDetail params={match.params.id} />
          }}
        />
        <PrivateRoute exact path="/howto">
          <HowTo />
        </PrivateRoute>
        <Route
          exact
          path="/howto/:id"
          render={({ match, location }) => {
            return <HowToDetail params={match.params.id} />
          }}
        />
        <PrivateRoute exact path="/codes">
          <Codes />
        </PrivateRoute>
      </Layout>
    </Switch>
  )
}

export default Routes;
