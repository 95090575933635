import React, { useState, useMemo, useEffect, useRef } from "react"
import { useMutation } from "react-query"
import { useTable, useRowSelect, useFilters, useSortBy } from "react-table"
import { toast } from "react-hot-toast"

import { ContentWrapper, Input, Card, Button, Table } from "@components"

import { ReactComponent as Gift } from "@images/gift.svg"
import { ReactComponent as User } from "@images/user.svg"
import { ReactComponent as Basket } from "@images/basket.svg"
import { ReactComponent as Star } from "@images/star.svg"
import debby from "@images/debby.png"
import userImg from "@images/user.svg"

import { getHowTos, addHowTo } from "@apis"

import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai"

const FileUploadButton = ({ innerRef, ...rest }) => (
  <div className="flex p-2">
    <input ref={innerRef} type="file" id="upload" hidden />
    <label
      htmlFor="upload"
      className="text-red text-xs uppercase cursor-pointer"
    >
      Upload Video
    </label>
  </div>
)

function Videos() {
  const upload = useRef(null)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  const addHowToVideo = useMutation(params => {
    addHowTo(params)
  })

  const addVideo = async e => {
    e.preventDefault()

    await addHowToVideo.mutateAsync({
      title: title,
      description: description,
      file: upload.current.files[0]
    })

    const result = await getHowTos()
    setData(result)
    window.location.reload()
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: ""
      },
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Description",
        accessor: "description"
      },
      {
        Header: "File",
        accessor: "file",
        Cell: row => {
          // console.log("props %o", row.row.original.id)
          return (
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                {row.row.original.file ? (
                  <video width="800" height="500" controls>
                    <source src={row.row.original.file} type="video/mp4" />
                  </video>
                ) : null}
              </div>
            </div>
          )
        }
      }
    ],
    []
  )

  const [data, setData] = useState([])

  useEffect(() => {
    getHowTos().then(result => {
      setData(result)
    })
  }, [])

  return (
    <ContentWrapper breadcrumb="Dashboard / Users">
      <div className="flex flex-col w-full">
        <form>
          <h2>Add a new video</h2>
          <div className="grid gap-4 grid-cols-2 md:grid-cols-2">
            <div className="flex flex-col w-full">
              <Input
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder={"Title"}
              />
            </div>
            <div className="flex flex-col w-full">
              <button
                className={`${true ? "bg-darkBlue" : "bg-transparent"} mx-1`}
                style={{ marginTop: 22, height: 30 }}
                onClick={addVideo}
              >
                <p
                  className={`uppercase text-xs py-2 px-4 font-bold ${
                    true ? "text-white" : "text-btnSecondaryText"
                  }`}
                >
                  Add
                </p>
              </button>
            </div>
          </div>
          <div className="grid gap-4 grid-cols-2 md:grid-cols-2">
            <div className="flex flex-col w-full">
              <textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder={"Description"}
                style={{
                  borderColor: "rgba(229, 231, 235, 1)",
                  borderWidth: 1,
                  padding: 20,
                  marginBottom: 40
                }}
              ></textarea>
            </div>
            <div className="flex flex-col w-full">
              <Card
                title="Video file"
                rightContent={<FileUploadButton innerRef={upload} />}
              >
                {upload.current?.files[0] && (
                  <div className="flex justify-center items-center">
                    <p className="text-xs text-gray-400 ">
                      {upload.current.files[0].name}
                    </p>
                  </div>
                )}
              </Card>
            </div>
          </div>
        </form>
        <div className="flex flex-col w-full"></div>
        <h2>All videos</h2>
        <Table columns={columns} data={data} filter={"title"} />
        <button>
          <div className="bg-tableHeaderBg py-2">
            <p className="text-btnSecondaryText text-xs text-center">
              show next 10 results
            </p>
          </div>
        </button>
      </div>
    </ContentWrapper>
  )
}

export default Videos
