import React, { useState, useEffect, useRef } from "react"
import { useMutation } from "react-query"

import { ContentWrapper, Input, Card, Button } from "@components"

import { getHowToItem, updateHowTo } from "@apis"

const FileUploadButton = ({ innerRef, ...rest }) => (
  <div className="flex p-2">
    <input ref={innerRef} type="file" id="upload" hidden />
    <label
      htmlFor="upload"
      className="text-red text-xs uppercase cursor-pointer"
    >
      Upload Video
    </label>
  </div>
)

function HowToDetail(props) {
  // console.log(props.params)
  const upload = useRef(null)
  const id = props.params
  const [item, setItem] = useState({})
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [file, setFile] = useState("")

  const updateE = useMutation(params => {
    updateHowTo(params)
    getHowToDetails()
  })

  const update = async e => {
    e.preventDefault()

    let howto = {
      id: id,
      title: title,
      description: description
    }

    if (upload.current.files[0]) {
      howto.file = upload.current.files[0]
    }

    // console.log("filee ", howto)

    await updateE.mutateAsync(howto)

    getHowToDetails()
  }

  function getHowToDetails() {
    getHowToItem(id)
      .then(resp => {
        // console.log(resp)
        setItem(resp)
        setTitle(resp.title)
        setDescription(resp.description)
      })
      .catch(e => console.error(e))
  }

  useEffect(() => {
    getHowToDetails()
  }, [])

  return (
    <ContentWrapper breadcrumb="Dashboard / Users">
      <div className="flex flex-col w-full">
        <form>
          <h2>HowTo Details</h2>
          <Input
            value={title}
            label="Title"
            onChange={e => setTitle(e.target.value)}
          />
          <Input
            value={description}
            label="Description"
            onChange={e => setDescription(e.target.value)}
          />
          <label>Video </label>
          {item.file ? (
            <video width="200" height="200" controls>
              <source src={item.file} type="video/mp4" />
            </video>
          ) : (
            <p></p>
          )}

          <Card
            title="Video file"
            rightContent={<FileUploadButton innerRef={upload} />}
          >
            {upload.current?.files[0] && (
              <div className="flex justify-center items-center">
                <p className="text-xs text-gray-400 ">
                  {upload.current.files[0].name}
                </p>
              </div>
            )}
          </Card>

          <Button
            label="Save"
            type="submit"
            onClick={update}
            style={{ marginTop: 20, width: 200 }}
          />
        </form>
      </div>
    </ContentWrapper>
  )
}

export default HowToDetail
