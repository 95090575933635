import React from 'react';
import { Link, useLocation } from "react-router-dom"
import useAuth from "@context/useAuth"

import { storeMenus, analyticsMenus } from "./menus"

import dummyLogo from "@images/dummyLogo.png"
import { ReactComponent as Logout } from "@images/logout.svg"

const Layout = ({ children }) => {
  const { onLogout } = useAuth()
  const { pathname } = useLocation()
  const [show, setShow] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(null)
  const [breadcrumb, setBreadcrumb] = React.useState("Dashboard /")
  return (
    <div className="w-full h-full">
      <div className="flex flex-no-wrap">
        {/* Sidebar starts */}
        <div
          className="w-64 absolute lg:relative bg-lightGray shadow h-screen flex-col justify-between hidden lg:flex pb-12"
          style={{
            background: "linear-gradient(180deg, #024669 0%, #2E84B3 100%)"
          }}
        >
          <div className="px-3">
            <div
              className="bg-white h-16 w-full flex justify-center items-center py-20 mt-10"
              style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            >
              <img src={dummyLogo} alt="" />
            </div>
            <ul
              className="mt-2"
              style={{
                borderWidth: 2,
                borderColor: "white",
                marginTop: 0,
                paddingTop: 60
              }}
            >
              {storeMenus.map(menu => (
                <Link
                  onMouseEnter={() => setIsHovered(menu.path)}
                  onMouseLeave={() => setIsHovered(null)}
                  onClick={() => setBreadcrumb(menu.breadcrumb)}
                  key={menu.title}
                  to={menu.path}
                >
                  <li
                    className={`flex w-full justify-start cursor-pointer items-center mb-6 ${
                      pathname === menu.path ? "text-white" : "text-menu"
                    } hover:text-blue`}
                  >
                    <div className="py-1 px-3  flex items-center justify-center text-xs">
                      {menu.icon({
                        isActive:
                          pathname === menu.path || isHovered === menu.path
                      })}
                    </div>
                    <div className="flex items-center">
                      <span className="text-sm text-white">{menu.title}</span>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
            <div className="flex items-center mt-10 mb-4"></div>
            <div className="mt-14">
              <button onClick={() => onLogout()} to="/">
                <li className="flex w-full justify-start cursor-pointer items-center mb-6 text-menu">
                  <div className="py-1 px-3  flex items-center justify-center text-xs">
                    <Logout
                      className="fill-current text-menu text-white"
                      style={{ color: "#ffffff" }}
                    />
                  </div>
                  <div className="flex items-center">
                    <span className="text-sm text-white">Logout</span>
                  </div>
                </li>
              </button>
            </div>
          </div>
        </div>

        {/*Mobile responsive sidebar*/}
        <div
          className={
            show
              ? "w-full h-full absolute z-40 transform  translate-x-0 "
              : "   w-full h-full absolute z-40 transform -translate-x-full"
          }
        >
          <div
            className="bg-gray-800 opacity-50 w-full h-full absolute"
            onClick={() => setShow(!show)}
          />
          <div className="w-64 md:w-96 absolute z-40 bg-white shadow h-full flex-col justify-between lg:hidden pb-4 transition duration-150 ease-in-out">
            <div
              className="flex flex-col justify-between h-full"
              style={{
                background: "linear-gradient(180deg, #024669 0%, #2E84B3 100%)"
              }}
            >
              <div>
                <div className="flex items-center justify-between px-8 mt-10">
                  <div className="h-16 w-full flex items-center">
                    <img src={dummyLogo} alt="" />
                  </div>
                  <div
                    id="closeSideBar"
                    className="flex items-center justify-center h-10 w-10"
                    onClick={() => setShow(!show)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-x"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1={18} y1={6} x2={6} y2={18} />
                      <line x1={6} y1={6} x2={18} y2={18} />
                    </svg>
                  </div>
                </div>
                <div className="px-8">
                  <div className="flex items-center mt-10 mb-4">
                    <span className="text-sm text-white font-bold px-3 text-menu-title">
                      STORE
                    </span>
                  </div>
                  <ul className="mt-2">
                    {storeMenus.map(menu => (
                      <Link
                        onMouseLeave={() => setIsHovered(null)}
                        key={menu.title}
                        to={menu.path}
                      >
                        <li className="flex w-full justify-start cursor-pointer items-center mb-6 text-menu">
                          <div className="py-1 px-3  flex items-center justify-center text-xs">
                            {menu.icon({
                              isActive:
                                pathname === menu.path ||
                                isHovered === menu.path
                            })}
                          </div>
                          <div className="flex items-center">
                            <span className="text-sm text-white">
                              {menu.title}
                            </span>
                          </div>
                        </li>
                      </Link>
                    ))}
                  </ul>
                  <div className="mt-14">
                    <Link to="/">
                      <li className="flex w-full justify-start cursor-pointer items-center mb-6 text-menu">
                        <div className="py-1 px-3  flex items-center justify-center text-xs">
                          <Logout className="fill-current text-menu" />
                        </div>
                        <div className="flex items-center">
                          <span className="text-sm text-white">Logout</span>
                        </div>
                      </li>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pt-5">{children}</div>
      </div>
      <div
        className="text-gray-600 mr-8 visible lg:hidden absolute top-3 right-3 border-2 rounded"
        onClick={() => setShow(!show)}
        id="menu"
      >
        {show ? (
          ""
        ) : (
          <svg
            aria-label="Main Menu"
            aria-haspopup="true"
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-menu cursor-pointer"
            width={30}
            height={30}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1={4} y1={8} x2={20} y2={8} />
            <line x1={4} y1={12} x2={20} y2={12} />
            <line x1={4} y1={16} x2={20} y2={16} />
          </svg>
        )}
      </div>
    </div>
  )
}

export default Layout;
