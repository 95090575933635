import { loginApi } from "@apis"
import { Button, Checkbox, Input } from "@components"
import useAuth from "@context/useAuth"
import logo from "@images/nls-logo.png"
import React, { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { useMutation } from "react-query"
import { Link, useHistory, useLocation } from "react-router-dom"

const Login = () => {
  const { onLogin } = useAuth()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [remember, setRemember] = useState(false)

  const { state } = useLocation()
  const history = useHistory()

  const login = useMutation(params => loginApi(params))

  useEffect(() => {
   // onLogin({ token:'b19c12d1bd3c2c020d2780b2934d21feab426be8', remember })
    if (login?.isError) {
      if (typeof login?.error === "string") {
        toast.error(login?.error)
      } else {
        toast.error("Login Error")
      }
      login?.reset()
    }

    if (login?.isSuccess) {
      // console.log(">>>>>>> lo login")
       console.log(login)

      onLogin({ token: login?.data?.token, remember })
      history.replace(state?.from?.pathname)
    }
  }, [history, login, onLogin, remember, state?.from?.pathname])

  const handleLogin = async e => {
    e.preventDefault()
    await login.mutate({
      username,
      password
    })
  }

  return (
    <div className="xl:mx-96 lg:mx-60 flex justify-center items-center mx-auto">
      <div className="flex flex-auto flex-col items-center justify-center next-gradient p-16 relative" style={{minHeight:'100vh'}}>
        <div className="w-full items-center my-4 px-20">
          <h1 className="font-bold text-3xl text-white text-center">Welcome</h1>
          <p className="font-light text-xs text-white text-center py-3">
            Login to your account
          </p>
        </div>
        <div className="w-full items-center">
          <Input
            dark
            value={username}
            onChange={e => setUsername(e.target.value)}
            label="Username"
          />
          <Input
            dark
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
          />
          <div className="flex flex-row items-center justify-between">
            <Checkbox
              checked={remember}
              onChange={e => setRemember(e.target.checked)}
              label="Remember Me"
            />
            <Link
              to="/forgot-password"
              className="text-white text-xs px-2 underline"
            >
              Forgot Password?
            </Link>
          </div>
        </div>
        <div
          className=" inset-x-0 "
          style={{ marginTop:20,width:'100%' }}
        >
          <Button
            block
            loading={login?.isLoading}
            disabled={login?.isLoading || !username || !password}
            onClick={handleLogin}
            label="Sign In"
          />
          {/*<div className="flex flex-row justify-center items center my-5">
            <p className="text-white text-xs">Don't have an account?</p>
            <Link to="/register" className="text-white text-xs px-2 underline">
              Sign up now
            </Link>
  </div>*/}
        </div>
      </div>
    </div>
  )
}

export default Login
